var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:(_vm.getMarginCss(_vm.pro.style, _vm.isDesktopView))},[_c('div',{staticClass:"d-flex justify-content-between align-items-center couponContainer",style:(_vm.getCouponContainerStyles(_vm.pro.style,_vm.pageBgColor))},[_c('div',{staticStyle:{"flex":"0.7"}},[_c('p',{staticClass:"m-0 p-0",style:(_vm.getColorCss(_vm.pro.style) +
                     
                      _vm.getColorWithOpacity(_vm.pro.style) +
                      _vm.getTextRotate(_vm.pro.style) +
                      _vm.getLineHeight(_vm.pro.style) +
                      _vm.getTextAlign(_vm.pro.style) +
                      _vm.getFontStyle(_vm.pro.style))},[_vm._v(_vm._s(_vm.pro.couponText))])]),_c('div',{staticClass:"d-flex justify-content-center",staticStyle:{"flex":"0.3"}},[_c('button',{staticClass:"btn",style:(_vm.getCouponBtnStyles(_vm.pro.style)),on:{"click":_vm.triggerCoupon}},[_vm._v(_vm._s(_vm.pro.couponBtnText))])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }