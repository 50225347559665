var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row mt-5 w-85 big-div",staticStyle:{"position":"relative","z-index":"100"},style:(_vm.getPageDesign() +
      `zoom:${this.pageZoomValue ? this.pageZoomValue : 90}%;`)},[(
        (_vm.pageDesign.pageType == 'resultPage' &&
          _vm.pageDesign.imageBlock.src !== '') ||
        (_vm.pageDesign.pageType == 'noResultPage' &&
          _vm.pageDesign.imageBlock.noResultSrc !== '' &&
          _vm.getImageAlign == 'no')
      )?_c('div',{staticClass:"bg",style:(_vm.getbackgroundImage(
          _vm.IsImageAdded,
          _vm.pageDesign.imageBlock,
          _vm.getImageAlign,
          _vm.isDesktopView
        ) + _vm.getBgDivStyles())}):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showLeftImage && _vm.pageDesign.pageType !== 'resultPage'),expression:"showLeftImage && pageDesign.pageType !== 'resultPage'"}],staticClass:"col-6 col-xs-12 col-md-6 col-sm-12 px-0 col-12 col-xs-12 px-0",class:{
        'col-12 col-lg-12 col-xs-12': !_vm.isDesktopView,
        'image-division': _vm.isDesktopView,
      },style:(_vm.calculateImageWidth(
          _vm.pageDesign.imageBlock,
          _vm.isDesktopView,
          _vm.IsImageVariableWidthActive
        ))},[_c('div',{class:_vm.isDesktopView ? 'image-division' : '',on:{"click":_vm.activeImageCustomize}},[_c('div',{style:(_vm.height100(_vm.isDesktopView))},[_c('LazyImage',{key:_vm.ImgBlockImageSrc,class:[
              `pic-${_vm.getSelectedPage.order}`,
              _vm.ImgBlockImageSrc == '/images/default_start_page.png'
                ? 'default-bg'
                : '',
            ],style:(_vm.getUniversalImageStyle(
                _vm.isDesktopView,
                _vm.pageDesign.imageBlock.style,
                _vm.IsImageVariableWidthActive,
                _vm.pageDesign.imageBlock
              ) +
              _vm.getImageOpacity(_vm.pageDesign.imageBlock.style) +
              _vm.getImageRotation(_vm.pageDesign.imageBlock.style)),attrs:{"src":_vm.MainImgSrc(_vm.ImgBlockImageSrc),"alt":""}})],1)]),_c('button',{staticClass:"hide close-icon",attrs:{"id":"clear"},on:{"click":_vm.deleteImage}},[_c('svg',{attrs:{"width":"32","height":"32","viewBox":"0 0 32 32","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M0 4C0 1.79086 1.79086 0 4 0H28C30.2091 0 32 1.79086 32 4V28C32 30.2091 30.2091 32 28 32H4C1.79086 32 0 30.2091 0 28V4Z","fill":"black","fill-opacity":"0.5"}}),_c('path',{attrs:{"d":"M10.2857 23.2592C10.2857 24.4 11.1428 25.3333 12.1905 25.3333H19.8095C20.8571 25.3333 21.7143 24.4 21.7143 23.2592V10.8148H10.2857V23.2592ZM12.1905 12.8888H19.8095V23.2592H12.1905V12.8888ZM19.3333 7.70366L18.3809 6.66663H13.619L12.6666 7.70366H9.33331V9.77774H22.6666V7.70366H19.3333Z","fill":"white"}})])])]),_c('div',{class:!_vm.IsImageAdded ? 'col-12' : _vm.imageAddedLayout,style:(_vm.getMainPaddingCss(_vm.pageDesign.pageStyles, _vm.isDesktopView) +
        _vm.getScrollCSS(
          _vm.pageDesign.pageStyles,
          _vm.isDesktopView,
          _vm.pageDesign.imageBlock.style,
          _vm.pageDesign.pageType
        ) +
        _vm.calculateContentWidth(
          _vm.pageDesign.imageBlock,
          _vm.isDesktopView,
          _vm.IsImageVariableWidthActive,
          _vm.pageDesign.pageStyles,
          _vm.pageDesign.pageType
        ))},[_c('div',{},[_c('div',[(_vm.pageDesign.pageType == 'resultPage')?_c('div',[_c('draggable',_vm._b({staticClass:"dragArea list-group",staticStyle:{"cursor":"move"},attrs:{"group":"actions","chosen-class":"chosen","drag-class":"drag"},on:{"start":function($event){_vm.dragging = true},"end":function($event){_vm.dragging = false},"change":_vm.handleDraggableElementDrop},model:{value:(_vm.pageDesignBlocks),callback:function ($$v) {_vm.pageDesignBlocks=$$v},expression:"pageDesignBlocks"}},'draggable',_vm.dragOptions,false),_vm._l((_vm.pageDesign.blocksArray),function(pro,ind){return _c('div',{key:'preview' + ind,class:_vm.dragging ? 'dragElementBorder' : null,attrs:{"draggable":"false"},on:{"click":function($event){return _vm.changeCustomize(ind)}}},[_c('div',{staticClass:"content designBlockElement",staticStyle:{"position":"relative"},style:(_vm.getHeightWidth(pro.style) + _vm.getDivBorderCss(pro)),attrs:{"id":"preview"}},[(pro.customizeStatus)?_c('EditorAction',{attrs:{"deleteCondition":_vm.getDeleteButtonCondition(pro),"currElementIndex":ind,"currElementType":pro.type}}):_vm._e(),(pro.type == 'text')?_c('div',{style:(_vm.getColorCss(pro.style) +
                      _vm.getPaddingCss(pro.style) +
                      _vm.getMarginCss(pro.style, _vm.isDesktopView) +
                      _vm.getColorWithOpacity(pro.style) +
                      _vm.getTextRotate(pro.style) +
                      _vm.getLineHeight(pro.style) +
                      _vm.getTextAlign(pro.style) +
                      _vm.getFontStyle(pro.style, _vm.isDesktopView)),domProps:{"innerHTML":_vm._s(_vm.getEditorPersonalisedValueStyle(pro.content))}}):_vm._e(),(pro.type == 'html')?_c('div',{style:(_vm.getMarginCss(pro.style, _vm.isDesktopView) +
                      _vm.getColorWithOpacity(pro.style) +
                      _vm.getTextRotate(pro.style) +
                      _vm.getTextAlign(pro.style))},[_c('HtmlElement',{attrs:{"pro":pro,"isDesktopView":_vm.isDesktopView}})],1):_vm._e(),(pro.type == 'imageComp')?_c('div',[_c('InlineImageComp',{attrs:{"pro":pro,"isDesktopView":_vm.isDesktopView}})],1):_vm._e(),(pro.type == 'product')?_c('div',[(_vm.getViewType == 'detailOnly')?_c('div',{staticClass:"row"},[(pro.showListView)?_c('div',{staticClass:"row w-100 m-0 p-0"},_vm._l((_vm.getProductsList),function(product,index){return _c('div',{key:index,staticClass:"col-12 mb-2"},[_c('div',{staticClass:"row m-0 p-0 mb-3",style:(_vm.detailProductContainerStyle(_vm.isDesktopView) +
                              _vm.getCardBackground(pro.style) +
                              _vm.getCardBorderCSS(pro.style))},[_c('div',{staticClass:"p-0 m-0",class:_vm.getProductCarouselImageClass(_vm.isDesktopView),style:(_vm.getProductCarouselImageStyle(_vm.isDesktopView))},[_c('div',{staticClass:"w-100 h-100",staticStyle:{"position":"relative"}},[_c('LazyImage',{style:(_vm.getDetailProductImageFit(pro.style) +
                                    _vm.getDetailProductImageRatio(pro.style) +
                                    _vm.getImageborderRadius(pro.style)),attrs:{"src":product.image,"alt":""}}),(
                                    pro.showProductRank &&
                                    index <= pro.productRankOption.length - 1
                                  )?_c('div',{staticClass:"d-flex justify-content-center",staticStyle:{"position":"absolute","top":"-2%","left":"50%","transform":"translateX(-50%)","z-index":"100"}},[_c('p',{staticStyle:{"text-align":"center"},style:(_vm.getProductRankCss(pro.style))},[_c('span',[_vm._v(_vm._s(pro.productRankOption[index].text))])])]):_vm._e()],1)]),_c('div',{staticClass:"h-100 w-100 d-flex flex-column",class:_vm.isDesktopView ? ' col-md-6' : 'col-12',style:(_vm.getPagePadding(pro.style, _vm.isDesktopView))},[_c('div',{staticClass:"d-flex flex-column flex-grow-1"},[_c('div',[_c('p',{style:(_vm.getTitleStyles(pro.style))},[_vm._v(" "+_vm._s(_vm.getProductInfoDetail( pro.useProductVariant, product, "title" ))+" ")])]),_c('div',{},[_c('div',{style:(_vm.getDescriptionStyles(_vm.isDesktopView))},[(!pro.hideDescription)?_c('div',[(!pro.useBulletDescription)?_c('div',{style:(_vm.getDescriptionStyles(_vm.isDesktopView))},[_c('p',{staticClass:"mb-0",style:(_vm.getPriceColor(pro.style)),domProps:{"innerHTML":_vm._s(product.description)}})]):_c('div',_vm._l((product.productAdvantage),function(features,index2){return _c('div',{key:index2},[_c('div',{staticClass:"d-flex align-items-center mt-1",style:(_vm.getBulletStyles(pro.style))},[_c('div',{staticClass:"mr-3"},[_c('i',{staticClass:"fa",class:pro.selectedBulletIcon})]),_c('span',[_vm._v(_vm._s(features.text))])])])}),0)]):_vm._e()])])]),_c('div',{staticClass:"mt-auto"},[(!_vm.isHidePrice)?_c('p',{staticClass:"m-0 p-0",style:(_vm.getPriceColor(pro.style))},[(_vm.currencyPos == 'left')?_c('span',{class:_vm.currencySpace ? 'mr-1' : null},[_vm._v(_vm._s(_vm.currentCurrency))]):_vm._e(),_vm._v(_vm._s(_vm.getProductInfoDetail( pro.useProductVariant, product, "price" ))),(_vm.currencyPos == 'right')?_c('span',{class:_vm.currencySpace ? 'ml-1' : null},[_vm._v(_vm._s(_vm.currentCurrency))]):_vm._e()]):_vm._e(),_c('div',{staticClass:"mt-2"},[(
                                      pro.useProductVariant &&
                                      product &&
                                      product.variants
                                    )?_c('div',{staticClass:"pb-2"},[_c('VaraintSelectComp',{attrs:{"pro":pro,"product":product}})],1):_vm._e(),(pro.useAddToCart)?_c('button',{style:(_vm.getProductButtonCss(pro.style))},[_vm._v(" "+_vm._s(pro.useAddToCartText || "Add to Cart")+" ")]):_c('button',{style:(_vm.getProductButtonCss(pro.style))},[_vm._v(" "+_vm._s(pro.buttonText)+" ")])])])])])])}),0):_c('div',{staticClass:"col-12"},[_c('carousel',{staticClass:"ProductCarousel",attrs:{"perPage":1,"paginationEnabled":_vm.checkProductLength(_vm.getProductsList),"navigationEnabled":_vm.checkProductLength(_vm.getProductsList)}},_vm._l((_vm.getProductsList),function(product,index){return _c('slide',{key:index},[_c('div',{staticClass:"row m-0 p-0",style:(_vm.detailProductContainerStyle(_vm.isDesktopView) +
                                _vm.getCardBackground(pro.style) +
                                _vm.getCardBorderCSS(pro.style))},[_c('div',{staticClass:"p-0 m-0",class:_vm.getProductCarouselImageClass(_vm.isDesktopView),style:(_vm.getProductCarouselImageStyle(_vm.isDesktopView))},[_c('div',{staticClass:"w-100 h-100",staticStyle:{"position":"relative"}},[_c('LazyImage',{style:(_vm.getDetailProductImageFit(pro.style) +
                                      _vm.getDetailProductImageRatio(pro.style) +
                                      _vm.getImageborderRadius(pro.style)),attrs:{"src":product.image,"alt":""}}),(
                                      pro.showProductRank &&
                                      index <=
                                        pro.productRankOption.length - 1
                                    )?_c('div',{staticClass:"d-flex justify-content-center",staticStyle:{"position":"absolute","top":"0%","left":"50%","transform":"translateX(-50%)","z-index":"100"}},[_c('p',{staticStyle:{"text-align":"center"},style:(_vm.getProductRankCss(pro.style))},[_c('span',[_vm._v(_vm._s(pro.productRankOption[index].text))])])]):_vm._e()],1)]),_c('div',{staticClass:"h-100 w-100 d-flex flex-column",class:_vm.isDesktopView ? ' col-md-6' : 'col-12',style:(_vm.getPagePadding(pro.style, _vm.isDesktopView))},[_c('div',{staticClass:"d-flex flex-column flex-grow-1"},[_c('div',{staticClass:"mb-2"},[_c('p',{staticClass:"mb-0",style:(_vm.getTitleStyles(pro.style))},[_vm._v(" "+_vm._s(_vm.getProductInfoDetail( pro.useProductVariant, product, "title" ))+" ")]),(
                                        product.productReview &&
                                        _vm.isJudgeMeInstalled
                                      )?_c('div',{staticClass:"Review",style:(_vm.getPriceColor(pro.style)),domProps:{"innerHTML":_vm._s(product.productReview)}}):_vm._e()]),_c('div',{},[_c('div',{style:(_vm.getDescriptionStyles(_vm.isDesktopView))},[(!pro.hideDescription)?_c('div',[(!pro.useBulletDescription)?_c('div',{style:(_vm.getDescriptionStyles(
                                              _vm.isDesktopView
                                            ))},[_c('p',{staticClass:"mb-0",style:(_vm.getPriceColor(pro.style)),domProps:{"innerHTML":_vm._s(product.description)}})]):_c('div',_vm._l((product.productAdvantage),function(features,index2){return _c('div',{key:index2},[_c('div',{staticClass:"d-flex align-items-center mt-1",style:(_vm.getBulletStyles(pro.style))},[_c('div',{staticClass:"mr-3"},[_c('i',{staticClass:"fa",class:pro.selectedBulletIcon})]),_c('span',[_vm._v(_vm._s(features.text))])])])}),0)]):_vm._e()])])]),_c('div',{staticClass:"mt-auto"},[(!_vm.isHidePrice)?_c('p',{staticClass:"m-0 p-0",style:(_vm.getPriceColor(pro.style))},[(_vm.currencyPos == 'left')?_c('span',{class:_vm.currencySpace ? 'mr-1' : null},[_vm._v(_vm._s(_vm.currentCurrency))]):_vm._e(),_vm._v(_vm._s(_vm.getProductInfoDetail( pro.useProductVariant, product, "price" ))),(_vm.currencyPos == 'right')?_c('span',{class:_vm.currencySpace ? 'ml-1' : null},[_vm._v(_vm._s(_vm.currentCurrency))]):_vm._e()]):_vm._e(),_c('div',{staticClass:"mt-2"},[(
                                        pro.useProductVariant &&
                                        product &&
                                        product.variants
                                      )?_c('div',{staticClass:"pb-2"},[_c('VaraintSelectComp',{attrs:{"pro":pro,"product":product}})],1):_vm._e(),(pro.useAddToCart)?_c('button',{style:(_vm.getProductButtonCss(pro.style))},[_vm._v(" "+_vm._s(pro.useAddToCartText || "Add to Cart")+" ")]):_c('button',{style:(_vm.getProductButtonCss(pro.style))},[_vm._v(" "+_vm._s(pro.buttonText)+" ")])])])])])])}),1)],1)]):_vm._e(),(_vm.getViewType == 'detailList')?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 mb-3"},[_c('div',{staticClass:"row m-0 p-0",style:(_vm.detailProductContainerStyle(_vm.isDesktopView) +
                            _vm.getCardBackground(pro.style) +
                            _vm.getCardBorderCSS(pro.style))},[_c('div',{staticClass:"p-0 m-0",class:_vm.getProductCarouselImageClass(_vm.isDesktopView),style:(_vm.getProductCarouselImageStyle(_vm.isDesktopView))},[_c('div',{staticClass:"w-100 h-100 position-relative"},[_c('LazyImage',{style:(_vm.getDetailProductImageFit(pro.style) +
                                  _vm.getDetailProductImageRatio(pro.style) +
                                  _vm.getImageborderRadius(pro.style)),attrs:{"src":_vm.SampleProducts[0].image,"alt":""}}),(pro.showProductRank)?_c('div',{staticClass:"d-flex justify-content-center",staticStyle:{"position":"absolute","top":"-2%","left":"50%","transform":"translateX(-50%)","z-index":"100"}},[_c('p',{staticStyle:{"text-align":"center"},style:(_vm.getProductRankCss(pro.style))},[_c('span',[_vm._v(_vm._s(pro.productRankOption[0].text))])])]):_vm._e()],1)]),_c('div',{staticClass:"h-100 w-100 d-flex flex-column",class:_vm.getProductCarouselImageClass(_vm.isDesktopView),style:(_vm.getPagePadding(pro.style, _vm.isDesktopView))},[_c('div',{staticClass:"d-flex flex-column flex-grow-1"},[_c('div',{staticClass:"mb-2"},[_c('p',{staticClass:"mb-0",style:(_vm.getTitleStyles(pro.style))},[_vm._v(" "+_vm._s(_vm.getProductInfoDetail( pro.useProductVariant, _vm.SampleProducts[0], "title" ))+" ")]),(
                                    _vm.SampleProducts[0].productReview &&
                                    _vm.isJudgeMeInstalled
                                  )?_c('div',{staticClass:"Review",style:(_vm.getPriceColor(pro.style)),domProps:{"innerHTML":_vm._s(_vm.SampleProducts[0].productReview)}}):_vm._e()]),_c('div',{},[_c('div',{style:(_vm.getDescriptionStyles(_vm.isDesktopView))},[(!pro.hideDescription)?_c('div',[(!pro.useBulletDescription)?_c('div',{style:(_vm.getDescriptionStyles(_vm.isDesktopView))},[_c('p',{staticClass:"mb-0",style:(_vm.getPriceColor(pro.style)),domProps:{"innerHTML":_vm._s(_vm.SampleProducts[0].description)}})]):_c('div',_vm._l((_vm.SampleProducts[0]
                                          .productAdvantage),function(features,index2){return _c('div',{key:index2},[_c('div',{staticClass:"d-flex align-items-center mt-1",style:(_vm.getBulletStyles(pro.style))},[_c('div',{staticClass:"mr-3"},[_c('i',{staticClass:"fa",class:pro.selectedBulletIcon})]),_c('span',[_vm._v(_vm._s(features.text))])])])}),0)]):_vm._e()])])]),_c('div',{staticClass:"mt-auto"},[(!_vm.isHidePrice)?_c('p',{staticClass:"m-0 p-0 mt-3",style:(_vm.getPriceColor(pro.style))},[(_vm.currencyPos == 'left')?_c('span',{class:_vm.currencySpace ? 'mr-1' : null},[_vm._v(_vm._s(_vm.currentCurrency))]):_vm._e(),_vm._v(_vm._s(_vm.getProductInfoDetail( pro.useProductVariant, _vm.SampleProducts[0], "price" ))),(_vm.currencyPos == 'right')?_c('span',{class:_vm.currencySpace ? 'ml-1' : null},[_vm._v(_vm._s(_vm.currentCurrency))]):_vm._e()]):_vm._e(),_c('div',{staticClass:"mt-2"},[(
                                    pro.useProductVariant &&
                                    _vm.SampleProducts[0] &&
                                    _vm.SampleProducts[0].variants
                                  )?_c('div',{staticClass:"pb-2"},[_c('VaraintSelectComp',{attrs:{"pro":pro,"product":_vm.SampleProducts[0]}})],1):_vm._e(),(pro.useAddToCart)?_c('button',{style:(_vm.getProductButtonCss(pro.style))},[_vm._v(" "+_vm._s(pro.useAddToCartText || "Add to Cart")+" ")]):_c('button',{style:(_vm.getProductButtonCss(pro.style))},[_vm._v(" "+_vm._s(pro.buttonText)+" ")])])])])])]),_c('div',{staticClass:"col-12 mb-3"},[_c('p',{style:(_vm.getMoreOptionStyles(pro.style))},[_vm._v(" "+_vm._s(_vm.getMoreOptionText(pro.moreOptionText))+" ")])]),_vm._l((_vm.getDetailListProducts(
                          _vm.SampleProducts
                        )),function(product,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(index < _vm.displayProductsLength(pro)),expression:"index < displayProductsLength(pro)"}],key:index,staticClass:"mb-3",class:_vm.isDesktopView
                            ? `col-${12 / pro.productsPerPage}`
                            : `col-${12 / pro.productsPerPageMobile}`},[_c('div',{staticClass:"card shadow-sm d-flex flex-column h-100 mx-auto position-relative",style:(_vm.getCardBackground(pro.style) +
                            _vm.getCardBorderCSS(pro.style) +
                            _vm.getPagePadding(pro.style, _vm.isDesktopView) +
                            _vm.getFontFamily(pro.style) +
                            _vm.ListProductMaxWidth(
                              _vm.isDesktopView
                                ? pro.productsPerPage
                                : pro.productsPerPageMobile
                            ))},[(
                              pro.showProductRank &&
                              index <= pro.productRankOption.length - 1
                            )?_c('div',{staticClass:"d-flex justify-content-center",staticStyle:{"position":"absolute","top":"-2%","left":"50%","transform":"translateX(-50%)","z-index":"100"}},[(pro.productRankOption[index + 1])?_c('p',{staticStyle:{"text-align":"center"},style:(_vm.getProductRankCss(pro.style))},[_c('span',[_vm._v(_vm._s(pro.productRankOption[index + 1].text))])]):_vm._e()]):_vm._e(),_c('div',{style:(_vm.ListImageAspectRatio(pro.style))},[_c('LazyImage',{staticStyle:{"position":"absolute","top":"0","left":"0","bottom":"0","right":"0","height":"100%","width":"100%"},style:(_vm.getImageFit(pro.style) +
                                _vm.getImageTopBorderCSs(pro.style)),attrs:{"src":product.image,"alt":""}})],1),_c('div',{staticClass:"mt-2 flex-grow-1",style:(_vm.getPaddingCss(pro.style))},[_c('div',{staticClass:"d-flex flex-column justify-content-between h-100"},[_c('div',{staticClass:"mb-2"},[_c('p',{staticClass:"mb-0",style:(_vm.getTitleStyles(pro.style))},[_vm._v(" "+_vm._s(_vm.getProductInfoDetail( pro.useProductVariant, product, "title" ))+" ")]),(
                                    product.productReview &&
                                    _vm.isJudgeMeInstalled
                                  )?_c('div',{staticClass:"Review",style:(_vm.getPriceColor(pro.style)),domProps:{"innerHTML":_vm._s(product.productReview)}}):_vm._e()]),_c('div',{},[(
                                    !pro.hideDescription &&
                                    !pro.showHeirarchyDesciption
                                  )?_c('div',[(!pro.useBulletDescription)?_c('div',{style:(_vm.getDescriptionStyles(_vm.isDesktopView))},[_c('p',{staticClass:"mb-0",style:(_vm.getPriceColor(pro.style)),domProps:{"innerHTML":_vm._s(product.description)}})]):_c('div',_vm._l((product.productAdvantage),function(features,index2){return _c('div',{key:index2},[_c('div',{staticClass:"d-flex align-items-center mt-1",style:(_vm.getBulletStyles(pro.style))},[_c('div',{staticClass:"mr-3"},[_c('i',{staticClass:"fa",class:pro.selectedBulletIcon})]),_c('span',[_vm._v(_vm._s(features.text))])])])}),0)]):_vm._e(),_c('div',{staticClass:"mt-auto"},[(!_vm.isHidePrice)?_c('p',{staticClass:"m-0 p-0 mt-3",style:(_vm.getPriceColor(pro.style))},[(_vm.currencyPos == 'left')?_c('span',{class:_vm.currencySpace ? 'mr-1' : null},[_vm._v(_vm._s(_vm.currentCurrency))]):_vm._e(),_vm._v(_vm._s(_vm.getProductInfoDetail( pro.useProductVariant, product, "price" ))),(_vm.currencyPos == 'right')?_c('span',{class:_vm.currencySpace ? 'ml-1' : null},[_vm._v(_vm._s(_vm.currentCurrency))]):_vm._e()]):_vm._e(),_c('div',{staticClass:"mt-2"},[(
                                        pro.useProductVariant &&
                                        product &&
                                        product.variants
                                      )?_c('div',{staticClass:"pb-2"},[_c('VaraintSelectComp',{attrs:{"pro":pro,"product":product}})],1):_vm._e(),(pro.useAddToCart)?_c('button',{style:(_vm.getProductButtonCss(pro.style))},[_vm._v(" "+_vm._s(pro.useAddToCartText || "Add to Cart")+" ")]):_c('button',{style:(_vm.getProductButtonCss(pro.style))},[_vm._v(" "+_vm._s(pro.buttonText)+" ")])])])])])])])])})],2):_vm._e(),(_vm.getViewType == 'list')?_c('div',{staticClass:"row"},_vm._l((_vm.SampleProducts),function(product,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(index < _vm.displayProductsLength(pro)),expression:"index < displayProductsLength(pro)"}],key:index,staticClass:"mb-3",class:_vm.isDesktopView
                            ? `col-${12 / pro.productsPerPage}`
                            : `col-${12 / pro.productsPerPageMobile}`},[_c('MultipleViewSingleCard',{attrs:{"pro":pro,"index":index,"isTest":true,"isDesktopView":_vm.isDesktopView,"productsLength":_vm.SampleProducts.length,"product":product,"displayProductsLength":_vm.displayProductsLength,"isJudgeMeInstalled":_vm.isJudgeMeInstalled,"isHidePrice":_vm.isHidePrice,"CurrencySign":'$',"currencyPos":_vm.currencyPos,"currencySpace":_vm.currencySpace,"disableAddtoCartBtn":true,"AddToCart":_vm.AddToCart,"goToURLClicked":_vm.goToURLClicked,"getProductInfoDetail":_vm.getProductInfoDetail,"checkFileType":_vm.checkFileType,"getImageFit":_vm.getImageFit,"getImageTopBorderCSs":_vm.getImageTopBorderCSs,"getTitleStyles":_vm.getTitleStyles,"getPriceColor":_vm.getPriceColor}})],1)}),0):_vm._e(),(_vm.getViewType == 'flipCards')?_c('div',{staticClass:"row"},_vm._l((_vm.SampleProducts),function(product,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(index < _vm.displayProductsLength(pro)),expression:"index < displayProductsLength(pro)"}],key:index,staticClass:"mb-3",class:_vm.isDesktopView
                            ? `col-${12 / pro.productsPerPage}`
                            : `col-${12 / pro.productsPerPageMobile}`},[_c('FlipCards',{attrs:{"pro":pro,"index":index,"isTest":true,"isDesktopView":_vm.isDesktopView,"productsLength":_vm.SampleProducts.length,"product":product,"displayProductsLength":_vm.displayProductsLength,"isJudgeMeInstalled":_vm.isJudgeMeInstalled,"isHidePrice":_vm.isHidePrice,"CurrencySign":'$',"currencyPos":_vm.currencyPos,"currencySpace":_vm.currencySpace,"disableAddtoCartBtn":true,"AddToCart":_vm.AddToCart,"goToURLClicked":_vm.goToURLClicked,"getProductInfoDetail":_vm.getProductInfoDetail,"checkFileType":_vm.checkFileType,"getImageFit":_vm.getImageFit,"getImageTopBorderCSs":_vm.getImageTopBorderCSs,"getTitleStyles":_vm.getTitleStyles,"getPriceColor":_vm.getPriceColor}})],1)}),0):_vm._e(),(_vm.getViewType == 'groupProducts')?_c('div',{staticClass:"row m-0 p-0"},[_c('div',{staticClass:"row w-100 m-0 p-0"},[_c('GroupProductsComp',{attrs:{"pro":pro,"isTest":true,"isDesktopView":_vm.isDesktopView,"products":_vm.SampleProducts,"displayProductsLength":_vm.displayProductsLength,"isJudgeMeInstalled":_vm.isJudgeMeInstalled,"isHidePrice":_vm.isHidePrice,"CurrencySign":'$',"currencyPos":_vm.currencyPos,"currencySpace":_vm.currencySpace,"disableAddtoCartBtn":true,"AddToCart":_vm.AddToCart,"goToURLClicked":_vm.goToURLClicked,"getProductInfoDetail":_vm.getProductInfoDetail,"checkFileType":_vm.checkFileType,"getImageFit":_vm.getImageFit,"getImageTopBorderCSs":_vm.getImageTopBorderCSs,"getTitleStyles":_vm.getTitleStyles,"getPriceColor":_vm.getPriceColor}})],1)]):_vm._e()]):_vm._e(),(pro.type == 'button')?_c('div',{style:('display:flex;justify-content:' +
                      pro.style.position +
                      ';')},[_c('button',{style:(_vm.getButtonCss(pro.style, _vm.isDesktopView)),on:{"click":function($event){return _vm.handleBtnClicked(pro)}}},[_c('span',{style:({
                          opacity: `${pro.style.textOpacity}%`,
                          fontSize: `${pro.style.fontSize}px`,
                        })},[_vm._v(" "+_vm._s(pro.text)+" ")]),_vm._v(" "+_vm._s(pro.style.alignItems)+" ")])]):_vm._e(),(pro.type == 'socialLinks')?_c('SocialLinksComp',{attrs:{"pro":pro,"isDesktopView":_vm.isDesktopView}}):_vm._e(),(pro.type == 'shareLink')?_c('ShareLinksComp',{attrs:{"pro":pro,"isDesktopView":_vm.isDesktopView}}):_vm._e(),(pro.type == 'coupon')?_c('CouponComp',{attrs:{"pro":pro,"isDesktopView":_vm.isDesktopView,"pageBgColor":_vm.CurrentPage.pageDesign.pageStyles.backgroundColor,"isLive":false}}):_vm._e()],1)])}),0)],1):_c('div',[_c('draggable',_vm._b({staticClass:"dragArea list-group",staticStyle:{"cursor":"move"},attrs:{"group":"actions","chosen-class":"chosen","drag-class":"drag"},on:{"start":function($event){_vm.noResultDragging = true},"end":function($event){_vm.noResultDragging = false},"change":_vm.handleDraggableElementDrop},model:{value:(_vm.pageDesignNoResultBlocks),callback:function ($$v) {_vm.pageDesignNoResultBlocks=$$v},expression:"pageDesignNoResultBlocks"}},'draggable',_vm.dragOptions,false),_vm._l((_vm.pageDesign.NoResultBlocksArray),function(pro,ind){return _c('div',{key:'preview' + ind,class:_vm.noResultDragging ? 'dragElementBorder' : null,attrs:{"draggable":"false"},on:{"click":function($event){return _vm.changeCustomize(ind)}}},[_c('div',{staticClass:"content designBlockElement",staticStyle:{"position":"relative"},style:(_vm.getHeightWidth(pro.style) + _vm.getDivBorderCss(pro)),attrs:{"id":"preview"}},[(pro.customizeStatus)?_c('EditorAction',{attrs:{"deleteCondition":_vm.getDeleteButtonCondition(pro),"currElementIndex":ind,"currElementType":pro.type}}):_vm._e(),(pro.type == 'text')?_c('div',{style:(_vm.getColorCss(pro.style) +
                      _vm.getPaddingCss(pro.style) +
                      _vm.getMarginCss(pro.style, _vm.isDesktopView) +
                      _vm.getColorWithOpacity(pro.style) +
                      _vm.getTextRotate(pro.style) +
                      _vm.getLineHeight(pro.style) +
                      _vm.getTextAlign(pro.style) +
                      _vm.getFontStyle(pro.style, _vm.isDesktopView)),domProps:{"innerHTML":_vm._s(_vm.getEditorPersonalisedValueStyle(pro.content))}}):_vm._e(),(pro.type == 'button')?_c('div',{style:('display:flex;justify-content:' +
                      pro.style.position +
                      ';')},[_c('button',{style:(_vm.getButtonCss(pro.style, _vm.isDesktopView)),on:{"click":function($event){return _vm.handleBtnClicked(pro)}}},[_c('span',{style:({
                          opacity: `${pro.style.textOpacity}%`,
                          fontSize: `${pro.style.fontSize}px`,
                        })},[_vm._v(" "+_vm._s(pro.text)+" ")]),_vm._v(" "+_vm._s(pro.style.alignItems)+" ")])]):_vm._e(),(pro.type == 'socialLinks')?_c('SocialLinksComp',{attrs:{"pro":pro,"isDesktopView":_vm.isDesktopView}}):_vm._e(),(pro.type == 'shareLink')?_c('ShareLinksComp',{attrs:{"pro":pro,"isDesktopView":_vm.isDesktopView}}):_vm._e(),(pro.type == 'imageComp')?_c('div',[_c('InlineImageComp',{attrs:{"pro":pro,"isDesktopView":_vm.isDesktopView}})],1):_vm._e()],1)])}),0)],1)])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showRightImage && _vm.pageDesign.pageType !== 'resultPage'),expression:"showRightImage && pageDesign.pageType !== 'resultPage'"}],staticClass:"col-6 col-xs-12 col-md-6 col-sm-12 px-0 col-12 col-xs-12 px-0",class:{
        'col-12 col-lg-12 col-xs-12': !_vm.isDesktopView,
        'image-division': _vm.isDesktopView,
      },style:(_vm.calculateImageWidth(
          _vm.pageDesign.imageBlock,
          _vm.isDesktopView,
          _vm.IsImageVariableWidthActive
        ))},[_c('div',{class:_vm.isDesktopView ? 'image-division' : '',on:{"click":_vm.activeImageCustomize}},[_c('div',{style:(_vm.height100(_vm.isDesktopView))},[_c('LazyImage',{key:_vm.ImgBlockImageSrc,class:[
              `pic-${_vm.getSelectedPage.order}`,
              _vm.ImgBlockImageSrc == '/images/default_start_page.png'
                ? 'default-bg'
                : '',
            ],style:(_vm.getUniversalImageStyle(
                _vm.isDesktopView,
                _vm.pageDesign.imageBlock.style,
                _vm.IsImageVariableWidthActive,
                _vm.pageDesign.imageBlock
              ) +
              _vm.getImageOpacity(_vm.pageDesign.imageBlock.style) +
              _vm.getImageRotation(_vm.pageDesign.imageBlock.style)),attrs:{"src":_vm.MainImgSrc(_vm.ImgBlockImageSrc),"alt":""}})],1)]),_c('button',{staticClass:"hide close-icon",attrs:{"id":"clear"},on:{"click":_vm.deleteImage}},[_c('svg',{attrs:{"width":"32","height":"32","viewBox":"0 0 32 32","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M0 4C0 1.79086 1.79086 0 4 0H28C30.2091 0 32 1.79086 32 4V28C32 30.2091 30.2091 32 28 32H4C1.79086 32 0 30.2091 0 28V4Z","fill":"black","fill-opacity":"0.5"}}),_c('path',{attrs:{"d":"M10.2857 23.2592C10.2857 24.4 11.1428 25.3333 12.1905 25.3333H19.8095C20.8571 25.3333 21.7143 24.4 21.7143 23.2592V10.8148H10.2857V23.2592ZM12.1905 12.8888H19.8095V23.2592H12.1905V12.8888ZM19.3333 7.70366L18.3809 6.66663H13.619L12.6666 7.70366H9.33331V9.77774H22.6666V7.70366H19.3333Z","fill":"white"}})])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }